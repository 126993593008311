<template>
  <div aria-labelledby="success page" ref="successPage">
    <div class="flex flex-col justify-center items-center box-style">
      <div
        class="text-3xl text-dark-purple font-bold pt-12 pb-3"
        data-cy="add-success-header"
      >
        Success!
      </div>
      <div class="text-xl pb-3">
        Your entry {{ success_message }}
      </div>
      <div class="grid grid-cols-2 gap-4 items-center text-center pb-16">
        <button
          class="inline-block bg-purple text-white rounded-3xl py-1 hover:bg-dark-purple hover:text-white hover:no-underline w-40"
          @click="$router.push('/fieldworks/add')"
        >
          Add Another Fieldwork
        </button>
        <button
          class="inline-block bg-purple text-white rounded-3xl py-1 hover:bg-dark-purple hover:text-white hover:no-underline w-40"
          @click="$router.push('/fieldworks')"
        >
          View All Fieldwork
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: THIS PAGE SHOULD ONLY BE ACCESSIBLE AFTER ADDING A LOG
// TODO: ADD ROUTE GUARD TO THIS PAGE... Maybe? Route guard should include a check for JWT
export default {
  name: "FieldworksSuccess",
  mounted() {
    this.$refs.successPage.focus(); 
  },
  props: {
    entry_type: {
      type: String,
      default: "experience",
    },
    isUpdate: {
      type: String,
      required: true,
    },
  },
  computed: {
    format_entry_type: function () {
      let formatted_entry_type = this.entry_type.replace(/_/g, " ");
      formatted_entry_type = formatted_entry_type.split(" ");
      formatted_entry_type = formatted_entry_type.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      formatted_entry_type = formatted_entry_type.join(" ");
      return formatted_entry_type;
    },
    success_message: function () {
      // console.log(this.isUpdate);
      return this.isUpdate === "true"
        ? "was successfully updated."
        : "was successfully submitted for approval.";
    },
  },
};
</script>

<style scoped></style>
